import repository from './repository'

const resource = 'billings'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  show (id, params) {
    return repository.get(`${resource}/${id}`, { params })
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
}
