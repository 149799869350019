import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

import { calibriBold, calibriRegular } from '@/fonts/calibri.font'
import { VDataFooter } from 'vuetify/lib'
import hericaLogoDataUri from '@/data-uri/herica-logo-2.datauri'

let doc = new jsPDF()
let pageWidth
let pageHeight

const addFonts = () => {
  doc.addFileToVFS(calibriBold.postScriptName, calibriBold.encoding)
  doc.addFileToVFS(calibriRegular.postScriptName, calibriRegular.encoding)
  doc.addFont('Calibri Bold.TTF', 'calibri', 'bold')
  doc.addFont('Calibri Regular.ttf', 'calibri', 'normal')
}

const getTextWidth = (text = null, size = 12) => {
  const MM_TO_POINT_RATIO = 2.83465
  if (!text) return 0
  return (doc.getStringUnitWidth(text) * size) / MM_TO_POINT_RATIO
}

const generateField = attributes => {
  const {
    label = '',
    labelFont = 'calibri',
    labelSize = 12,
    labelTextColor = [0, 0, 0],
    coordinates = [10, 10],
    lineLength = 71,
    textLength = null,
    value = '',
    valueFont = 'calibri',
    valueSize = 11,
    valueTextColor = [0, 0, 0],
    delimiter = null,
  } = attributes

  const [x, y] = coordinates
  const labelAttributes = [label].concat(coordinates)
  doc.setFont(labelFont)
  doc.setFontSize(labelSize)
  doc.setTextColor(...labelTextColor)
  doc.text(...labelAttributes)

  const textWidth = !textLength ? getTextWidth(label, labelSize) : textLength
  const labelEnd = x + textWidth
  const delimiterLength = getTextWidth(delimiter)

  doc.setFont(valueFont)
  doc.setFontSize(valueSize)
  doc.setTextColor(...valueTextColor)
  doc.text(value, labelEnd + delimiterLength + 2, y)
  if (delimiter) doc.text(delimiter, labelEnd, y)
  doc.line(labelEnd + delimiterLength, y + 1, labelEnd + delimiterLength + lineLength, y + 1)
  return textWidth
}

const generateText = (args = {}) => {
  const {
    font = 'calibri',
    fontStyle = 'normal',
    fontSize = 11,
    color = [0, 0, 0],
    text = '',
    coordinates = [0, 0],
  } = args
  doc.setFont(font, fontStyle)
  doc.setFontSize(fontSize)
  doc.setTextColor(...color)
  doc.text(text, ...coordinates)
  return getTextWidth(text, fontSize)
}

const initializeJsPDF = title => {
  doc = new jsPDF()
  addFonts()
  doc.setProperties({
    title,
    unit: 'mm',
    format: 'a4',
  })
  pageWidth = doc.internal.pageSize.getWidth()
  pageHeight = doc.internal.pageSize.getHeight()
}

const preview = () => {
  doc.output('dataurlnewwindow')
}

const subtractToLength = (length, text, size) => length - getTextWidth(text, size)

const header = () => {
  const [x, y] = [127, 20]
  doc.addImage(hericaLogoDataUri, 'PNG', 20, 19.6, 18.6, 16.08)
  const header1Width = generateText({
    text: 'Herica Animal Feed Trading',
    fontSize: 16,
    color: [169, 148, 83],
    coordinates: [x + 0, y + 0],
  })
  generateText({
    text: ('Sangi, Ipil'),
    fontSize: 10,
    coordinates: [
      subtractToLength(x + header1Width, 'Sangi, Ipil', 10),
      y + 5 + 0,
    ],
  })
  generateText({
    text: 'Ormoc City, Leyte',
    fontSize: 10,
    coordinates: [
      subtractToLength(x + header1Width, 'Ormoc City, Leyte', 10),
      y + 10 + 0,
    ],
  })
  generateText({
    text: '+63 917 150 9667',
    fontSize: 10,
    coordinates: [
      subtractToLength(x + header1Width, '+63 917 150 9667', 10),
      y + 15 + 0,
    ],
  })
  doc.setFillColor('#BF8F00')
  doc.line(20, y + 20, 190, y + 20, 'F')
}

const defaultItems = () => {
  const arr = []
  for (let i = 0; i < 15; i++) {
    arr.push([])
  }
  return arr
}

const billingStatement = args => {
  const { items = defaultItems(), totalAmount = '' } = args || {}
  let tableMeta = null
  generateText({
    text: 'Billing Statement:',
    fontSize: 16,
    color: [127, 95, 0],
    coordinates: [20, 95],
  })
  doc.autoTable({
    theme: 'grid',
    styles: {
      lineWidth: 0.2,
      lineColor: [201, 201, 201],
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
    },
    startY: 100,
    tableWidth: 170,
    margin: 20,
    columnStyles: {
      item: { halign: 'center', fontSize: 8 },
      invoiceNo: { halign: 'center', fontSize: 8 },
      date: { halign: 'center', fontSize: 8 },
      particulars: { halign: 'left', fontSize: 8 },
      amount: { halign: 'right', fontSize: 8 },
    },
    head: [
      [
        { content: 'Item', styles: { halign: 'center', cellWidth: 20 } },
        { content: 'Invoice No.', styles: { halign: 'center', cellWidth: 30 } },
        { content: 'Date', styles: { halign: 'center', cellWidth: 20 } },
        { content: 'Particulars', styles: { halign: 'center' } },
        { content: 'Amount', styles: { halign: 'center' } },
      ],
    ],
    body: [
      ...items,
      [
        { content: 'TOTAL', colSpan: 4, styles: { halign: 'center' } },
        { content: totalAmount, styles: { halign: 'right' } },
      ],
    ],
    columns: [
      { header: 'Item', dataKey: 'item' },
      { header: 'Invoice No.', dataKey: 'invoiceNo' },
      { header: 'Date', dataKey: 'date' },
      { header: 'Particulars', dataKey: 'particulars' },
      { header: 'Amount', dataKey: 'amount' },
    ],
    didParseCell (data) {
      if (tableMeta) return
      tableMeta = data.table
    },
  })
  return tableMeta.finalY
}

const billingForSection = (args = {}) => {
  const [x, y] = [20, 50]
  const {
    companyName = '',
    contactName = '',
    phone = '',
    email = '',
    date = '',
    billingNo = '',
  } = args
  generateText({
    text: 'Billing Statement For:',
    fontSize: 16,
    color: [127, 95, 0],
    coordinates: [x, y],
  })
  const label1Width = generateField({
    label: 'Company Name',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x, y + 10],
    value: companyName,
    valueSize: 10,
  })
  generateField({
    label: 'Contact Name',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x, y + 15],
    textLength: label1Width,
    value: contactName,
    valueSize: 10,
  })
  generateField({
    label: 'Phone#',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x, y + 20],
    textLength: label1Width,
    value: phone,
    valueSize: 10,
  })
  generateField({
    label: 'Fax #',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x, y + 25],
    textLength: label1Width,
    valueSize: 10,
  })
  generateField({
    label: 'Email',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x, y + 30],
    textLength: label1Width,
    value: email,
    valueSize: 10,
  })
  const poNoLength = generateField({
    label: 'PO No ',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x + 110, y + 15],
    value: billingNo,
    lineLength: 46,
    valueSize: 10,
  })
  generateField({
    label: 'Date ',
    fontSize: 10,
    delimiter: ':',
    coordinates: [x + 110, y + 10],
    lineLength: 46,
    textLength: poNoLength,
    value: date,
    valueSize: 10,
  })
}

const generateSignatureLine = (x, y) => {
  const lineLength = 58
  doc.setFillColor(0, 0, 0)
  doc.line(x, y, x + lineLength, y, 'F')

  doc.setFont('calibri', 'normal')
  doc.setFontSize(11)
  doc.text('MARIBEL G. VILLAR', x + 13, y - 2)
  doc.setTextColor(0, 0, 0)
  doc.text('MANAGER', x + 20, y + 4)
}

const footer = billingStatementFinalY => {
  generateText({
    fontSize: 14,
    text: 'BILLED BY:',
    coordinates: [125, billingStatementFinalY + 20],
  })
  generateSignatureLine(130, billingStatementFinalY + 40)
}

export default {
  generate (billingForData, billingStatementData) {
    initializeJsPDF()
    header()
    billingForSection(billingForData)
    const billingStatementFinalY = billingStatement(billingStatementData)
    footer(billingStatementFinalY)
    preview()
  },
}